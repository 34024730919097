.roll-box {
    flex-grow: 1;
    font-family: 'Bree Serif', sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 2.0rem;
    text-shadow: 2px 2px 2px #000;
}

.dice-box {
    display: block;
    margin: 0 auto 0 auto;
    height: 50px;
    width: 50px;
    background-color: #999;
    border-radius: 50%;
    box-shadow: 2px 2px 2px #000;
}