@import url(https://fonts.googleapis.com/css?family=Bree+Serif);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.title-header {
    font-family: 'Bree Serif', sans-serif;
}
.dice-text {
    font-family: 'Bree Serif', sans-serif;
    font-weight: bold;
    font-size: 2.0rem;
}
.rollEntry::after {
    content : ", ";
}

.rollEntry:last-of-type::after {
    content : "";
}
.roll-box {
    flex-grow: 1;
    font-family: 'Bree Serif', sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 2.0rem;
    text-shadow: 2px 2px 2px #000;
}

.dice-box {
    display: block;
    margin: 0 auto 0 auto;
    height: 50px;
    width: 50px;
    background-color: #999;
    border-radius: 50%;
    box-shadow: 2px 2px 2px #000;
}
